import React from 'react';
import { Stack, Tooltip, Button, Text } from '@chakra-ui/react';

const CustomButton = ({ label, onClick, tooltipLabel, disabled, style }) => {
    return (
        <Stack direction={'column'} spacing={0} paddingTop={"12px"}>
            <Tooltip label={tooltipLabel} ontSize="md" placement="bottom-start">
                <Button type="button" width="100%" flexGrow="1" onClick={onClick} disabled={disabled} style={{...style}}>
                    {label}
                </Button>
            </Tooltip>
        </Stack>
    );
};

export default CustomButton;