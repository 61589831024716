import React from 'react';
import { Stack, Text, Input } from '@chakra-ui/react';

const CustomTextField = ({ label, value, onChange }) => {
    return (
        <Stack direction="column" spacing="0%">
            <Text fontSize={"12px"} paddingBottom={"0"}>
                {label}
            </Text>
            <Input value={value} onChange={onChange} />
        </Stack>
    );
};

export default CustomTextField;