import React, { useEffect } from "react";
import { useClient } from '../../helpers/ClientContext';
import GenericContainer from "../common/GenericContainer";
import CustomButton from "../common/CustomButton";

const Downlink = () => {

    // Handle the MQTT client
    const client = useClient();
    useEffect(() => { }, [client]);

    const handleButtonClick = () => {
        client.publish_command(client.time, 'downlink', { "storage": "data" });
    };

    return (
        <GenericContainer h="100%" title="Downlink">
            <CustomButton
                label="Submit Downlink"
                onClick={handleButtonClick}
                tooltipLabel="Downlinks all data on board the spacecraft and any images that might have been captured."
            />
        </GenericContainer>
    );
};

export default Downlink;