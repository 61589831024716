import React, { useState, useEffect } from "react";
import { useClient } from '../../helpers/ClientContext';
import { Button, Stack, Tooltip } from "@chakra-ui/react";
import GenericContainer from "../common/GenericContainer";
import pointingData from "./pointingData"
import CustomSelect from "../common/CustomSelect";
import CustomNumberField from "../common/CustomNumberField";

/**
 * Renders the Guidance component.
 * This component allows the user to select a pointing type and fill out the corresponding fields.
 * It also handles the submission of the guidance pointing mode.
 */
const Guidance = () => {

    // Handle the MQTT client
    const client = useClient();
    useEffect(() => { }, [client]);

    const [pointingType, setPointingType] = useState('none');
    const [formData, setFormData] = useState({});

    const handlePointingTypeChange = (event) => {
        setPointingType(event.target.value);
        setFormData({});
    };

    const handleInputChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
        });
    };

    // Utility function to capitalize the first letter of a string
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const renderFields = () => {
        if (!pointingType || !pointingData[pointingType.toLowerCase()]) {
            return null;
        }

        const fields = pointingData[pointingType.toLowerCase()];

        return Object.keys(fields).map((key) => {
            const field = fields[key];
            const { type, options, min, max } = field;
            let defaultValue = field.default !== undefined ? field.default : '';

            const value = formData[key] !== undefined ? formData[key] : defaultValue;


            switch (type) {
                case 'float':
                    return (
                        <div key={key}>
                            <CustomNumberField
                                label={key}
                                value={value}
                                onChange={(value) => handleInputChange(key, value)}
                                min={min}
                                max={max} />
                        </div>
                    );

                case 'object':
                    return (
                        <div key={key}>
                            <CustomSelect
                                label={key}
                                value={value}
                                onChange={(e) => handleInputChange(key, e.target.value)}
                                options={options}
                            />
                        </div>
                    );

                default:
                    return null;
            }
        });
    };

    const handleButtonClick = () => {
        const pointingParams = {
            pointing: pointingType,
            ...formData,
        };

        const pointing = pointingType.toLowerCase();

        // If a field has not been touched, use the default value from pointingData
        Object.keys(pointingData[pointing]).forEach((key) => {
            if (formData[key] === undefined) {
                pointingParams[key] = pointingData[pointing][key].default;
            }
        });
        pointingParams["controller"] = (pointing === "none" ? "IDLE" : "MRP")

        client.publish_command(client.time, 'guidance', pointingParams);
        console.log();
    };

    // Check if all fields are filled out
    const isFormFilled = () => {
        const values = Object.values(formData);
        return values.every((val) => val !== '' && val !== undefined);
    };

    return (
        <GenericContainer h="100%" title="Guidance Pointing">
            <CustomSelect
                label={"Pointing"}
                value={pointingType}
                onChange={handlePointingTypeChange}
                options={Object.keys(pointingData).map((key) => key.toUpperCase())}
            />
            {pointingType && (
                <>
                    <form>
                        <Stack direction="column" spacing="2" bg="surface.foreground" rounded="md" mt="2">
                            {renderFields()}

                            <Stack direction="row" spacing="4%" align="center">
                                <Tooltip
                                    label="Submits a guidance pointing mode with a particular set of parameters."
                                    fontSize="md"
                                    placement="bottom-start"
                                >
                                    <Button type="button" width="100%"
                                        flexGrow="1" onClick={handleButtonClick} disabled={!isFormFilled()}>
                                        Submit Guidance
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    </form>
                </>
            )}
        </GenericContainer>
    );
};

export default Guidance;