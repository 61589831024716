import React, { useState, useEffect } from 'react';
import { Box, Text, Image, Stack, Tooltip } from '@chakra-ui/react';
import { useClient } from '../../helpers/ClientContext';
import { AiOutlineCheckCircle, AiOutlineWarning, AiOutlineQuestionCircle } from "react-icons/ai";
import { TiBatteryLow, TiBatteryFull } from "react-icons/ti";
import { PiCellSignalFullBold, PiCellSignalSlashBold  } from "react-icons/pi";
import { MdErrorOutline } from "react-icons/md";
import CustomSelect from './CustomSelect';
import logo from "../../assets/logo.png"

const TopBar = () => {
    const client = useClient();
    const [timeInSeconds, setTimeInSeconds] = useState(client.time);
    const [messages, setMessages] = useState(0);
    const [media, SetMedia] = useState(0);
    const [previousStation, setPreviousStation] = useState(client.currentStation);
    const [currentState, setCurrentState] = useState(client.currentState);
    const [currentGroundStation, setSelectedGroundStation] = useState(client.station);
    const [connectedID, setConnectedID] = useState(0);
    const [firstTimeSafe, setFirstTimeSafe] = useState(true);

    const handleGroundStationChange = (event) => {
        const selectedGroundStation = event.target.value;
        client.station = selectedGroundStation;
        setSelectedGroundStation(client.station)
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (client.time > 0) {
                setPreviousStation(client.currentStation);
                setCurrentState(client.currentState);
                setTimeInSeconds(client.time);
                setMessages(client.messagesReceived);
                SetMedia(client.imagesReceived);
            }

            // Check if connected
            if (client.connected) {
                setConnectedID(1);
                const timeSinceData = client.getTimeSinceUpdate();
                if (timeSinceData != undefined && timeSinceData < 3) {
                    setConnectedID(2);
                }
                
                const timeSincePing = client.getTimeSincePing();
                if (timeSincePing != undefined && timeSincePing > 7) {
                    setPreviousStation("???");
                    setCurrentState("???");
                }

                // If first time in safe mode and the state pops up, show a message
                if (currentState === "SAFE" && firstTimeSafe) {
                    setFirstTimeSafe(false);
                    alert("Your spacecraft has entered a 'SAFE' mode for the first time. When this occurs, the battery is in a very low state. Sun pointing has activated to charge your battery. No commands will be executed until the battery is sufficiently charged again.");
                }
            } else {
                setConnectedID(0);
                setPreviousStation("???");
                setCurrentState("???");
            }

        }, 200);

        return () => {
            clearInterval(interval);
        };
    }, [client.time]);

    const groundStations = [
        "GS_BRASILIA",
        "GS_DALLAS",
        "GS_DUBAI",
        "GS_HONOLULU",
        "GS_LONDON",
        "GS_NAIROBI",
        "GS_SINGAPORE",
        "GS_SYDNEY",
        "GS_TOKYO"
    ]

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const getConnectionIcon = (id) => {
        switch (id) {
            case 2:
                return (
                    <Tooltip label="The MQTT client is connected to a game successfully. Data is being received." placement="top">
                        <Box as="span" cursor="pointer">
                            <PiCellSignalFullBold size={24} color="green" />
                        </Box>
                    </Tooltip>
                );
            case 1:
                return (
                    <Tooltip label="The MQTT client is connected. Waiting for a game to start..." placement="top">
                        <Box as="span" cursor="pointer">
                            <PiCellSignalSlashBold size={24} color="yellow" />
                        </Box>
                    </Tooltip>
                );
            case 0:
            default:
                return (
                    <Tooltip label="The MQTT client is not connected. Please connect to a game in the settings tab." placement="top">
                        <Box as="span" cursor="pointer">
                            <MdErrorOutline size={24} color="red" />
                        </Box>
                    </Tooltip>
                );
        }
    };

    const getStateIcon = (state, connection) => {
        if (connection != 2) state = "???";
        switch (state) {
            case "NOMINAL":
                return (
                    <Tooltip label="The spacecraft is nominal. Commands can be sent to the spacecraft and the battery is at a suitable charge level." placement="top">
                        <Box as="span" cursor="pointer">
                            <TiBatteryFull size={24} color="green" />
                        </Box>
                    </Tooltip>
                );
            case "SAFE":
                return (
                    <Tooltip label="The spacecraft battery is low and is now in a safe state. No commands can be sent to the spacecraft until the battery is at a suitable charge level." placement="top">
                        <Box as="span" cursor="pointer">
                            <TiBatteryLow size={24} color="red" />
                        </Box>
                    </Tooltip>
                );
            case "???":
                return (
                    <Tooltip label="The spacecraft is currently not transmitting it's state. The spacecraft may not be in range of a ground station." placement="top">
                        <Box as="span" cursor="pointer">
                            <AiOutlineWarning size={24} color="yellow" />
                        </Box>
                    </Tooltip>
                );
            default:
                return (
                    <Tooltip label="Unknown state. The spacecraft state could not be decoded properly." placement="top">
                        <Box as="span" cursor="pointer">
                            <AiOutlineQuestionCircle size={24} color="gray" />
                        </Box>
                    </Tooltip>
                );
        }
    };

    return (
        <Stack direction="row" spacing={4} align="center" p="2" justify="space-between" bg="surface.foreground" w="100%" color="white" zIndex="10" top="0">
            <Box>
                <Image src={logo} alt="Logo" h="100%" />
            </Box>
            {getConnectionIcon(connectedID)}
            <Box>
                <Text fontSize="12px">Simulated Seconds</Text>
                <Text fontSize="xl">{Math.floor(timeInSeconds)} s</Text>
            </Box>
            <Box>
                <Text fontSize="12px">Datetime Elapsed</Text>
                <Text fontSize="xl">{formatTime(timeInSeconds)}</Text>
            </Box>
            <Box>
                <Text fontSize="12px">Messages Received</Text>
                <Text fontSize="xl">{messages}</Text>
            </Box>
            <Box>
                <Text fontSize="12px">Images Received</Text>
                <Text fontSize="xl">{media}</Text>
            </Box>
            <Stack direction="row" align="center">
                {getStateIcon(currentState, connectedID)}
                <Box>
                    <Text fontSize="12px">Spacecraft State</Text>
                    <Text fontSize="xl">{currentState}</Text>
                </Box>
            </Stack>
            <Box>
                <Text fontSize="12px">Spacecraft Station</Text>
                <Text fontSize="xl">{previousStation}</Text>
            </Box>
            <Stack direction='row'>
                <CustomSelect
                    label="Controlled Station"
                    value={currentGroundStation}
                    onChange={handleGroundStationChange}
                    options={groundStations}
                />
            </Stack>
        </Stack>
    );
};

export default TopBar;
