import React, { useEffect, useState } from "react";
import {
    Box,
    Flex,
    Heading,
    Stack,
    Button,
    Icon,
    IconButton,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaDatabase } from "react-icons/fa";
import { IoGameController } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { useClient } from "../../helpers/ClientContext";

const links = [
    {
        title: "Settings",
        icon: IoMdSettings,
        href: "/",
    },
    {
        title: "Controls",
        icon: IoGameController,
        href: "/controls",
    }
];

const SideBarLink = ({ isExpandedSideBar, isActive, title, icon, href, isEnabled }) => {
    return (
        <Link to={href}>
            <Button
                w="full"
                px={6}
                bg={isActive ? "surface.selected" : null}
                variant={isActive ? "solid" : "ghost"}
                justifyContent="flex-start"
                py={6}
                direction="row"
                borderRadius="0"
                borderRight={isActive ? "solid 5px" : "initial"}
                borderColor={isActive ? "surface.highlight" : "initial"}
                leftIcon={<Icon w={6} h={6} as={icon} />}
                _hover={{
                    background: "surface.highlight",
                }}
                disabled={!isEnabled}
            >
                {isExpandedSideBar && (
                    <Heading as="h3" size="md" ml={2}>
                        {title}
                    </Heading>
                )}
            </Button>
        </Link>
    );
};

const SideBarHeader = ({ isExpandedSideBar, handleToggleExpand }) => (
    <Stack
        direction={isExpandedSideBar ? "row" : "column-reverse"}
        spacing={4}
        height="fit-content"
        borderBottom="solid 1px"
        borderColor="gray.500"
        m={4}
        pb={3}
    >
        {isExpandedSideBar && (
            <Box
                borderRadius={4}
                background="linear-gradient(120.65deg, #5F46FD -11.99%, rgba(255, 255, 255, 0) 126.24%), #F3874A;"
                width="46px"
                height="46px"
            ></Box>
        )}
        {isExpandedSideBar && (
            <Heading alignSelf="flex-end" fontFamily={`"Press Start 2P"`} size="md">
                Cyber
                <br />
                Terminal
            </Heading>
        )}
        <IconButton
            variant="ghost"
            colorScheme="whiteAlpha"
            aria-label="Toggle Sidebar Expansion"
            alignSelf={isExpandedSideBar ? "flex-end" : "initial"}
            fontSize="20px"
            icon={isExpandedSideBar ? <FaChevronLeft /> : <FaChevronRight />}
            onClick={handleToggleExpand}
        />
    </Stack>
);

const SideBar = () => {
    const client = useClient();
    const [isConnected, setIsConnected] = useState(client.isConnected());

    useEffect(() => {
        const interval = setInterval(() => {
            setIsConnected(client.isConnected());
        }, 500);

        return () => clearInterval(interval);
    }, [client]);

    let location = useLocation();
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const sideBarWidth = isExpanded ? "260px" : "70px";

    return (
        <Flex
            w={sideBarWidth}
            minW={sideBarWidth}
            maxW={sideBarWidth}
            height="100vh"
            bg="surface.foreground"
            as="aside"
            direction="column"
            position="sticky"
            top="0"
        >
            <SideBarHeader
                isExpandedSideBar={isExpanded}
                handleToggleExpand={handleToggleExpand}
            />
            <Flex direction="column">
                {links.map((link) => (
                    <SideBarLink
                        isExpandedSideBar={isExpanded}
                        key={link.title}
                        {...link}
                        isActive={location.pathname === link.href}
                        isEnabled={link.href === "/" || isConnected}
                    />
                ))}
            </Flex>
        </Flex>
    );
};

export default SideBar;
