import React, { useEffect, useState } from "react";
import { useClient } from '../../helpers/ClientContext';
import GenericContainer from "../common/GenericContainer";
import CustomButton from "../common/CustomButton";
import CustomNumberField from "../common/CustomNumberField";
import CustomTextField from "../common/CustomTextField";
import { FaHourglassHalf, FaClipboard } from "react-icons/fa";
import { setCookie, getCookie } from "../../helpers/cookieUtils";
import { useLocation } from 'react-router-dom';
import { Grid, GridItem, Stack } from "@chakra-ui/react";

const GameSettings = () => {
    const client = useClient();

    const MIN_FREQUENCY = 400;
    const MAX_FREQUENCY = 999;

    // Load initial values from cookies or use default values
    const [server, setGameServer] = useState(
        decodeURIComponent(getCookie('server') || 'broker.hivemq.com/mqtt')
    );
    const [gameName, setGameName] = useState(
        decodeURIComponent(getCookie('gameName') || '')
    );
    const [teamID, setTeamID] = useState(parseInt(getCookie('teamID'), 10) || 0);
    const [frequency, setFrequency] = useState(parseInt(getCookie('frequency'), 10) || 400);
    const [key, setKey] = useState(parseInt(getCookie('key'), 10) || 0);
    const [isConnecting, setIsConnecting] = useState(false);

    // Function to check if all fields have valid values
    const isValid = () => {
        return server.trim() !== '' &&
            gameName.trim() !== '' &&
            Number.isInteger(teamID) &&
            teamID >= 0 &&
            teamID <= 99999999 &&
            Number.isInteger(frequency) &&
            frequency >= MIN_FREQUENCY &&
            frequency <= MAX_FREQUENCY &&
            Number.isInteger(key) &&
            key >= 0 &&
            key <= 100;
    };

    // useLocation hook gives you access to the location object including the query params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Check if uery parameter exists and set the IDs accordingly
    useEffect(() => {
        const serverParam = queryParams.get('server');
        if (serverParam) {
            setGameServer(serverParam);
        }
        const gameParam = queryParams.get('game');
        if (gameParam) {
            setGameName(gameParam);
        }
        const idParam = queryParams.get('id');
        if (idParam) {
            const idValue = parseInt(idParam, 10);
            if (!isNaN(idValue) && idValue >= 0 && idValue <= 99999999) {
                setTeamID(idValue);
            }
        }
        const freqParam = queryParams.get('frequency');
        if (freqParam) {
            const freqValue = parseInt(freqParam, 10);
            if (!isNaN(freqValue)) {
                setFrequency(freqValue);
            }
        }
        const keyParam = queryParams.get('key');
        if (keyParam) {
            const keyValue = parseInt(keyParam, 10);
            if (!isNaN(keyValue)) {
                setKey(keyValue);
            }
        }
    }, [queryParams]);

    const handleConnect = () => {
        setIsConnecting(true);
        if (isValid()) {
            client.connect(server, gameName, teamID, frequency, key);
        }
    };

    // Function to generate URL with parameters
    const generateURL = () => {
        const url = new URL(window.location.href);
        url.searchParams.set('server', server);
        url.searchParams.set('game', gameName);
        url.searchParams.set('id', teamID);
        url.searchParams.set('frequency', frequency);
        url.searchParams.set('key', key);
        return url.href;
    };

    // Function to copy URL to clipboard
    const copyURLToClipboard = () => {
        const url = generateURL();
        navigator.clipboard.writeText(url);
    };

    useEffect(() => {
        // Update cookies whenever state changes
        setCookie('server', server, 30);
        setCookie('gameName', gameName, 30);
        setCookie('teamID', teamID, 30);
        setCookie('frequency', frequency, 30);
        setCookie('key', key, 30);

        const interval = setInterval(() => {
            setIsConnecting(client.isConnecting);
        }, 200);

        return () => {
            clearInterval(interval);
        };

    }, [server, gameName, teamID, frequency, key]);

    return (
        <GenericContainer h="100%" title="Game Settings">
            <CustomTextField
                label="Server"
                value={server}
                onChange={(e) => setGameServer(e.target.value)}
            />
            <CustomTextField
                label="Game Name"
                value={gameName}
                onChange={(e) => setGameName(e.target.value)}
            />
            <CustomNumberField
                label="Team ID"
                value={teamID}
                onChange={(value) => setTeamID(value)}
                min={0}
                max={99999999}
            />
            <CustomNumberField
                label="Frequency"
                value={frequency}
                onChange={(value) => setFrequency(value)}
                min={MIN_FREQUENCY}
                max={MAX_FREQUENCY}
            />
            <CustomNumberField
                label="Key"
                value={key}
                onChange={(value) => setKey(value)}
                min={0}
                max={100}
            />
            <Grid templateColumns="repeat(10, 1fr)" gap={4}>
                <GridItem rowSpan={1} colSpan={9} minH="0" minW="0">
                    <CustomButton
                        label={isConnecting ? <FaHourglassHalf size="24px" color="white" /> : "Connect"}
                        onClick={handleConnect}
                        tooltipLabel="Connect to the game server with the provided configuration."
                        disabled={isConnecting || !isValid()}
                    />
                </GridItem>
                <GridItem rowSpan={1} colSpan={1} minH="0" minW="0">
                    <CustomButton
                        label={<FaClipboard size="24px" color="white" />}
                        onClick={copyURLToClipboard}
                        tooltipLabel="Copy the URL for this configuration to clipboard."
                    />
                </GridItem>
            </Grid>
        </GenericContainer>
    );
};

export default GameSettings;
