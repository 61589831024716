const pointingData = {
    none: {

    },
    inertial_pointing: {
        pitch: {
            type: 'float',
            min: -90,
            max: 90,
            default: 0,
        },
        roll: {
            type: 'float',
            min: -180,
            max: 180,
            default: 0,
        },
        yaw: {
            type: 'float',
            min: -180,
            max: 180,
            default: 0,
        },
    },
    sun_pointing: {
        target: {
            type: 'object',
            default: 'Solar Panel',
            options: ['Camera', 'Solar Panel'],
        },
    },
    velocity_pointing: {
        target: {
            type: 'object',
            default: 'Camera',
            options: ['Camera', 'Sensor'],
        },
    },
    nadir_pointing: {
        target: {
            type: 'object',
            default: 'Camera',
            options: ['Camera', 'Sensor'],
        },
        planet: {
            type: 'object',
            default: 'EARTH',
            options: ['SUN', 'MERCURY', 'VENUS', 'EARTH', 'MOON', 'MARS', 'JUPITER', 'SATURN', 'URANUS', 'NEPTUNE', 'PLUTO']
        },
    },
    ground_station_pointing: {
        target: {
            type: 'object',
            default: 'Camera',
            options: ['Camera', 'Sensor'],
        },
        station: {
            type: 'object',
            default: 'GS_SINGAPORE',
            options: [
                'GS_BRASILIA',
                'GS_DALLAS',
                'GS_DUBAI',
                'GS_HONOLULU',
                'GS_LONDON',
                'GS_NAIROBI',
                'GS_SINGAPORE',
                'GS_SYDNEY',
                'GS_TOKYO',
            ],
        },
    },
    location_pointing: {
        target: {
            type: 'object',
            default: 'Camera',
            options: ['Camera', 'Sensor'],
        },
        latitude: {
            type: 'float',
            min: -90,
            max: 90,
            default: 0,
        },
        longitude: {
            type: 'float',
            min: -180,
            max: 180,
            default: 0,
        },
        altitude: {
            type: 'float',
            min: 0,
            default: 0,
        },
        planet: {
            type: 'object',
            default: 'EARTH',
            options: ['SUN', 'MERCURY', 'VENUS', 'EARTH', 'MOON', 'MARS', 'JUPITER', 'SATURN', 'URANUS', 'NEPTUNE', 'PLUTO']
        },
    },
};

export default pointingData;