import React, { useState, useEffect } from "react";
import { useClient } from '../../helpers/ClientContext';
import GenericContainer from "../common/GenericContainer";
import CustomSelect from "../common/CustomSelect";
import CustomButton from "../common/CustomButton";
import CustomTextField from "../common/CustomTextField";
import CustomNumberField from "../common/CustomNumberField";

const CameraConfigure = () => {

    // Handle the MQTT client
    const client = useClient();
    useEffect(() => { }, [client]);

    const [camera, setCamera] = useState('');
    const [brightness, setBrightness] = useState(50);
    const [fov, setFOV] = useState(60);
    const [resolution, setResolution] = useState(500);
    const [imageName, setImageName] = useState('');

    const handleButtonClick = () => {
        if (camera === "" || camera === undefined) setCamera("Optical Camera")
        if (imageName === undefined) setImageName("");
        if (brightness === undefined) setBrightness(50);
        if (fov === undefined) setFOV(60);
        if (resolution === undefined) setResolution(500);
        let data = { "target": camera, "fov": fov, "resolution": resolution, "name": imageName }
        if (camera === "Optical Camera") data["brightness"] = brightness;
        client.publish_command(client.time, 'capture', data);
    };

    return (
        <GenericContainer h="100%" title="Camera Controls">
            <CustomSelect
                label="Camera"
                value={camera}
                onChange={(e) => setCamera(e.target.value)}
                options={["Optical Camera", "Infrared Camera"]}
            />
            <CustomNumberField
                label="Brightness"
                value={brightness}
                onChange={(value) => setBrightness(value)}
                enabled={camera != "Infrared Camera"}
                min={1}
                max={100}
            />
            <CustomNumberField
                label="Field of View"
                value={fov}
                onChange={(value) => setFOV(value)}
                min={1}
                max={150}
            />
            <CustomNumberField
                label="Resolution"
                value={resolution}
                onChange={(value) => setResolution(value)}
                min={128}
                max={1024}
            />
            <CustomTextField
                label="Image Name"
                value={imageName}
                onChange={(e) => setImageName(e.target.value)}
            />
            <CustomButton
                label="Submit Capture"
                onClick={handleButtonClick}
                tooltipLabel="Updates the camera configuration and takes an image, with an optional image name."
            />
        </GenericContainer>
    );
};

export default CameraConfigure;