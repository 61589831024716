import { extendTheme } from "@chakra-ui/react";

const themeExtension = {
    colors: {
        brand: {
            900: "#1a365d",
            800: "#153e75",
            700: "#2a69ac",
        },
        surface: {
            background: "#000424",
            foreground: "#00245e",
            selected: "#0080f7",
            highlight: "#0045b5",
        },
    },
    fonts: {
        heading: `Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
        body: `"Roboto Mono", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    },
    config: {
        initialColorMode: "dark",
        useSystemColorMode: false,
    },
};

const theme = extendTheme(themeExtension);

export default theme;
