import React from "react";
import { Grid, GridItem, Center } from "@chakra-ui/react";
import GameSettings from "../components/settings/GameSettings";

const Settings = () => {
    return (
        <Center h="80vh">
            <Grid templateColumns="repeat(1, 1fr)" gap={4} w="50%" p={4}>
                <GameSettings />
            </Grid>
        </Center>
    );
};

export default Settings;
