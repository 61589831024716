import React, { useEffect } from 'react';
import Client from './helpers/Client'; // Import your Client class
import { ClientProvider } from './helpers/ClientContext';
import { ChakraProvider } from "@chakra-ui/react";
import { Global, css } from "@emotion/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import theme from "./styles/theme";
import MainLayout from "./layouts/main";

import Settings from './control_views/Settings';
import Controls from "./control_views/Controls";
import Data from "./control_views/Data";

import TopBar from './components/common/TopBar';

const GlobalStyle = ({ children }) => {
    return (
        <>
            <Global
                styles={css`
          html {
            min-width: 360px;
            scroll-behavior: smooth;
          }
          #root {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
            background: ${theme.colors.surface.background};
            color: white;
          }
        `}
            />
            {children}
        </>
    );
};

function App() {
    
    // Create a new client
    const client = new Client();

    // Ensure the client disconnects at the end
    useEffect(() => {
        return () => {
            client.disconnect();
        };
    }, []);

    return (
        <ClientProvider client={client}>
            <Router>
                <ChakraProvider theme={theme}>
                    <GlobalStyle>
                        <MainLayout>
                            <Switch>
                                <Route path="/controls">
                                    <Controls />
                                </Route>
                                <Route path="/data">
                                    <Data />
                                </Route>
                                <Route exact path="/">
                                    <Settings />
                                </Route>
                            </Switch>
                        </MainLayout>
                    </GlobalStyle>
                </ChakraProvider>
            </Router>
        </ClientProvider>
    );
}

export default App;