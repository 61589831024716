import mqtt from 'mqtt';
import { saveAs } from 'file-saver';
const { v4: uuidv4 } = require('uuid');

const SERVER_NAME = 'wss://broker.hivemq.com/mqtt';
const SERVER_PORT = 8884;
const SERVER_TIMEOUT = 5;
const TOPIC_PREFIX = "NominalSystems/SatelliteCyberGame";

class Client {
    constructor() {
        this.instance = 0;
        this.station = "GS_SINGAPORE";
        this.client_name = `${this.id}_Team_${uuidv4()}`;
        this.connected = false;
        this.isConnecting = false;
        this.fail = false;
        this.callbacks = {};
        this.time = 0;
        this.recentUpdateTime = undefined;
        this.recentPingTime = undefined;
        this.imagesReceived = 0;
        this.messagesReceived = 0;
        this.currentStation = "???"
        this.currentState = "???"
    }

    get_game_topic_prefix() {
        return `${TOPIC_PREFIX}/${this.game_name}`;
    }

    get_time_topic() {
        return `${this.get_game_topic_prefix()}/Time`;
    }

    get_publish_topic() {
        return `${this.get_game_topic_prefix()}/Upload/${this.station}/${this.id}`;
    }

    get_data_topic(freq) {
        return `${this.get_game_topic_prefix()}/Downlink/${freq}/Data`;
    }

    get_bytes_topic(freq) {
        return `${this.get_game_topic_prefix()}/Downlink/${freq}/Bytes`;
    }

    /**
     * Returns whether the game has successfully connected to the client and
     * is able to run correctly.
     */
    isConnected () {
        return this.client != undefined && this.connected;
    }

    /**
     * Establishes a connection to the server using the specified parameters.
     */
    connect(server = "", gameName = "", teamID = 0, frequency = 0, key = 0) {

        // End the previous client
        this.disconnect();

        // Setup the initial variabels
        this.server = "wss://" + server;
        this.game_name = gameName;
        this.id = teamID;
        this.frequency = frequency;
        this.key = key;
        this.isConnecting = true;

        // Print a line to the console
        console.log(`Attempting to connect to the server '${this.server}'...`)

        // Create the client
        this.client = mqtt.connect(
            this.server,
            {
                port: SERVER_PORT,
                timeout: SERVER_TIMEOUT,
                clientId: this.client_name,
                protocolVersion: 5,
            }
        );

        // Setup the event handlers
        this.client.on('connect', this.on_connect.bind(this));
        this.client.on('message', this.on_message.bind(this));
        this.client.on('error', this.on_connect_fail.bind(this));
    }

    /**
     * Disconnects the client from the current MQTT topic and ensures that it is
     * no longer connected to the server.
     */
    disconnect() {
        if (this.client != undefined) {
            this.client.end();
            this.client = undefined;
        }
        this.connected = false;
        this.isConnecting = false;
    }

    on_connect(client, userdata, flags, rc) {
        console.log(`Client has sucessfully connected to the server!`);
        this.connected = true;
        this.isConnecting = false;

        // Subscribe to the base topics
        const topic_time = this.get_time_topic();
        this.callbacks[topic_time] = this.on_recv_time.bind(this);
        this.client.subscribe(topic_time)
        this.subscribe(this.frequency);
    }

    on_connect_fail(error) {
        console.log(`Client has failed to connect. Error: ${error}`);
        this.fail = true;
    }

    on_recv_time(topic, data) {
        this.recentUpdateTime = Date.now();
        const parsedData = JSON.parse(data);
        this.time = parseFloat(parsedData.Time);
        this.instance = parseInt(parsedData.Instance);
    }

    on_recv_data(topic, data) {
        this.messagesReceived += 1;
        data = this.decryptCaesarCipher(data, this.key);

        // Replace '\\n' with '\n' to ensure the JSON is valid
        data = data.replace(/\\n/g, '');
        data = data.replace(/\\r/g, '');
        const parsedData = JSON.parse(data);
        this.currentStation = parsedData.Station;
        if (parsedData.Type == "Ping") {
            this.currentState = parsedData.Data.State;
            this.recentPingTime = Date.now();
        }
    }

    /**
     * Retruns the length of time in seconds since the last 
     * update was received.
     */
    getTimeSinceUpdate() {
        if (this.recentUpdateTime == undefined) {
            return undefined;
        }
        return (Date.now() - this.recentUpdateTime) / 1000;
    }

    /**
     * Retruns the length of time in seconds since the last 
     * ping was received.
     */
    getTimeSincePing() {
        if (this.recentPingTime == undefined) {
            return undefined;
        }
        return (Date.now() - this.recentPingTime) / 1000;
    }

    get_num_messages(freq) {
        if (this.buffer_data[freq]) {
            return this.buffer_data[freq].length;
        }
        return 0;
    }

    get_messages(freq) {
        if (this.buffer_data[freq]) {
            const messages = this.buffer_data[freq];
            const startIndex = Math.max(messages.length - 10, 0); // Ensure we don't go negative
            return messages.slice(startIndex);
        }
        return [];
    }

    on_message(topic, message) {
        console.log(this.get_time_topic());
        if (!(topic in this.callbacks)) {
            console.error(`Undefined message on topic '${topic}'.`);
            return;
        }
        if (message.toString().charAt(0) === "{") {
            this.callbacks[topic](topic, message.toString());
        } else {
            // convert the message from the topic to a byte array
            let bytes = new Uint8Array(message);
            this.callbacks[topic](topic, bytes);
        }
    }

    saveMedia = (freq, name, data) => {
        const mimeType = this.getMimeTypeFromFilename(name);
        let blobData = new Blob([data], { type: mimeType });
        saveAs(blobData, `${this.instance}_${name}`);
    };

    getMimeTypeFromFilename = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        switch (extension) {
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            case 'wav':
                return 'audio/wav';
            default:
                return 'application/octet-stream'; // Default to generic binary file
        }
    };

    on_recv_bytes = (topic, message) => {
        const freq = parseInt(topic.split("/").slice(-2)[0]);
        const LENGTH = 50;

        // Read the name bytes and convert to string
        const nameBytes = message.slice(0, LENGTH);
        let name = "";

        // Convert nameBytes to a string without null characters
        for (let i = 0; i < LENGTH; i++) {
            const byte = nameBytes[i];
            if (byte !== 0) {
                name += String.fromCharCode(byte);
            }
        }

        // Remove any leading or trailing whitespace
        name = name.trim();

        // Extract the file data starting from the 50th index
        const fileData = message.slice(LENGTH);

        try {
            // Save the image data as a file
            this.saveMedia(freq, name, fileData);
            this.imagesReceived += 1;
        } catch (error) {
            console.error('Error processing media data:', error);
        }
    };

    publish_command(time, command, params, key = null) {
        if (this.currentState === "SAFE") {
            alert("Your spacecraft is in a 'SAFE' mode. No commands will be executed until the battery is sufficiently charged again.");
            return;
        }
        if (key === null) {
            key = this.key;
        }
        let p_str = "";
        for (let idx = 0; idx < Object.keys(params).length; idx++) {
            const p_key = Object.keys(params)[idx];
            p_str += `"${p_key}":"${params[p_key]}"`;
            if (idx < Object.keys(params).length - 1) {
                p_str += ", ";
            }
        }
        const data = `{"Time":${time},"Key":${key},"Command":"${command}","Parameters":{${p_str}}}`;
        this.client.publish(this.get_publish_topic(), data);
        console.log(`Published command: ${data} to topic: ${this.get_publish_topic()}`);
    }

    change_frequency(freq) {
        this.frequency = freq;
        this.subscribe(this.frequency);
    }

    subscribe(freq) {
        const topic_data = this.get_data_topic(freq);
        const topic_bytes = this.get_bytes_topic(freq);
        this.callbacks[topic_data] = this.on_recv_data.bind(this);
        this.callbacks[topic_bytes] = this.on_recv_bytes.bind(this);
        this.client.subscribe(topic_data);
        this.client.subscribe(topic_bytes);
    }

    decryptCaesarCipher(data, key) {

        // Ensure the key is an integer
        key = parseInt(key);

        // Handle the array
        if (Array.isArray(data)) {
            const result = [];
            for (const item of data) {
                result.push(this.decryptCaesarCipher(item, key));
            }
            return result;
        }

        // Handles the object for JSON data
        if (typeof data === 'object' && data !== null) {
            let json_string = JSON.stringify(data);
            json_string = this.decryptCaesarCipher(json_string, key);
            return JSON.parse(json_string);
        }

        let result = "";
        let isFloat = typeof data === 'number' && Number.isInteger(data);
        data = String(data);

        for (const char of data) {
            if (char.match(/[a-zA-Z0-9]/)) {
                let base = '0';
                if (char.match(/[A-Z]/)) base = 'A';
                else if (char.match(/[a-z]/)) base = 'a';

                let alphabetSize = 10;
                if (char.match(/[a-zA-Z]/)) alphabetSize = 26;

                let position = char.charCodeAt(0) - base.charCodeAt(0);
                let new_position = (position - key) % alphabetSize;
                if (new_position < 0) new_position += alphabetSize;

                let new_char = String.fromCharCode(base.charCodeAt(0) + new_position);
                result += new_char;
            } else {
                result += char;
            }
        }

        if (isFloat) {
            return parseFloat(result);
        }
        return result;
    }

    processAndDownloadCSV(freq, decKey) {
        /*
        const createCSV = (jsonData) => {
            const csvRows = [];
    
            // Group JSON data by 'Type' field
            const groupedData = {};
            jsonData.forEach((entry) => {
                const type = entry.Type;
                if (!groupedData[type]) {
                    groupedData[type] = [];
                }
                groupedData[type].push(entry);
            });
    
            // Create CSV rows for each group
            for (const type in groupedData) {
                if (groupedData.hasOwnProperty(type)) {
                    const groupEntries = groupedData[type];
                    const csv = groupEntries.map((entry) => {
                        return Object.values(entry).join(',');
                    }).join('\n');
                    csvRows.push(csv);
                }
            }
    
            return csvRows.join('\n\n'); // Separate CSV groups with double newline
        };

        const downloadCSV = (csv, filename) => {
            const blob = new Blob([csv], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        };

        const getData = (freq) => {
            if (this.buffer_data[freq]) {
                return this.buffer_data[freq];
            }
            return [];
        }

        // Loop through each JSON entry
        const data = getData(freq);
        // Loop through each JSON entry
        data.forEach((jsonData, index) => {
            const decryptedData = decryptData(jsonData, decKey);
            const csv = createCSV(decryptedData);
            const filename = `data_${index}.csv`;
            downloadCSV(csv, filename);
        });*/
    }
}

export default Client;
