import React, { createContext, useContext } from 'react';

const ClientContext = createContext(null);

export const useClient = () => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error("useClient must be used within a ClientProvider");
  }
  return context;
};

export const ClientProvider = ({ client, children }) => {
  return (
    <ClientContext.Provider value={client}>
      {children}
    </ClientContext.Provider>
  );
};

export { ClientContext };