import React, { useState } from 'react';
import { Stack, Text, Input, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';

const CustomNumberField = ({ label, value, onChange, min, max, enabled = true }) => {
    const [warning, setWarning] = useState('');

    // Utility function to capitalize the first letter of a string
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleChange = (event) => {
        if (event.target.value === '' || event.target.value === undefined) {
            setWarning('Invalid input. Please enter a valid number.');
            onChange('');
            return;
        }
        let inputValue = parseFloat(event.target.value);

        // Check if inputValue is a valid number
        if (!isNaN(inputValue)) {
            // Check against min and max if they are defined
            if (!isNaN(min) && inputValue < min) {
                setWarning(`Value must be greater than or equal to ${min}`);
            } else if (!isNaN(max) && inputValue > max) {
                setWarning(`Value must be less than or equal to ${max}`);
            } else {
                setWarning('');
            }
        } else {
            setWarning('Invalid input. Please enter a valid number.');
        }
        onChange(inputValue);
    };

    return (
        <Stack direction={'column'} spacing={0}>
            <Text fontSize={"12px"} paddingBottom={"0"}>
                {capitalizeFirstLetter(label)}
            </Text>
            <Input
                type="number"
                value={value}
                onChange={handleChange}
                isInvalid={!!warning}
                disabled={!enabled}
            />
            {warning && (
                <Alert status='error' marginTop={"1px"} marginBottom={"1px"}>
                    <AlertIcon />
                    <AlertDescription>The {label} value must be between {min} and {max}.</AlertDescription>
                </Alert>
            )}
        </Stack>
    );
};

export default CustomNumberField;
