import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import MessageViewer from "../components/data/MessageViewer";
import Downlink from "../components/controls/Downlink";

const Data = () => {
    return (
        <Grid templateColumns="repeat(3, 1fr)" gap={3} h="100%">
            <GridItem colSpan={2} rowSpan={3}>
                <MessageViewer />
            </GridItem>
            <GridItem colSpan={1} rowSpan={1}>
                <Downlink />
            </GridItem>
        </Grid>
    );
};

export default Data;
