import React from "react";
import { Heading } from "@chakra-ui/react";

const SectionHeading = ({ children, ...props }) => {
  return (
    <Heading as="h2" size="md" color="white" {...props} paddingBottom={2} textAlign={"center"}>
      {children}
    </Heading>
  );
};

export default SectionHeading;
