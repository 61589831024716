import { Flex, Stack } from "@chakra-ui/react";
import SectionHeading from "./SectionHeading";

const GenericContainer = ({ children, title, ...props }) => {
    return (
        <Flex
            direction="column"
            bg="surface.foreground"
            p={4}
            borderRadius={8}
            {...props}
        >
            <SectionHeading props>{(title != undefined) ? title.toUpperCase() : ""}</SectionHeading>
            <Stack direction="column" spacing="2" bg="surface.foreground" rounded="md" mt="0">
                {children}
            </Stack>
        </Flex>
    );
};

export default GenericContainer;
