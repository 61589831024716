import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import Guidance from "../components/controls/Guidance";
import Reset from "../components/controls/Reset";
import Downlink from "../components/controls/Downlink";
import CameraConfigure from "../components/controls/CameraConfigure";

const Controls = () => {
    return (
        <Grid templateColumns="repeat(2, 1fr)" gap={4} h="88vh">
            <GridItem rowSpan={1} colSpan={1} minH="0" minW="0">
                <Downlink />
            </GridItem>
            <GridItem rowSpan={2} colSpan={1} minH="0" minW="0">
                <Reset />
            </GridItem>
            <GridItem rowSpan={3} colSpan={1} minH="0" minW="0">
                <Guidance />
            </GridItem>
            <GridItem rowSpan={2} colSpan={1} minH="0" minW="0">
                <CameraConfigure />
            </GridItem>
        </Grid>
    );
};

export default Controls;