import React, { useEffect, useState } from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import GenericContainer from '../common/GenericContainer';
import { useClient } from '../../helpers/ClientContext';
import CustomButton from '../common/CustomButton';
import CustomNumberField from '../common/CustomNumberField';

const MessageViewer = () => {
    const [messages, setMessages] = useState([]);
    const [decodedMessages, setDecodedMessages] = useState([]);
    const client = useClient();
    const [decryptKey, setDecryptKey] = useState(client.key);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const fetchedMessages = await client.get_messages(client.frequency);
                const processedMessages = fetchedMessages.map((message) =>
                    message.replace(/\t/g, ' ').replace(/\n/g, ' ')
                );
                setMessages(processedMessages);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();

        // Set up interval to refresh messages every 5 seconds (adjust as needed)
        const intervalId = setInterval(fetchMessages, 1000);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, [client]);

    // Handler function for decrypt button click
    const handlePreviewClick = () => {
        const decrypted = messages.map((message) => client.decryptCaesarCipher(message, decryptKey));
        setDecodedMessages(decrypted);
    };

    const handleDecryptClick = () => {
        client.processAndDownloadCSV(client.frequency, decryptKey)
    }

    return (
        <GenericContainer h="100%" w="100%" overflow="hidden" title="Message Viewer">
            <Box bg="surface.background" p="2" rounded="md" maxWidth={"100%"}>
                {messages.map((message, index) => (
                    <Text key={index} whiteSpace="pre" fontSize="10px" overflow="hidden" textOverflow="ellipsis">
                        {message}
                    </Text>
                ))}
            </Box>
            <Stack direction="row" spacing="2">
                <CustomNumberField
                    label="Key"
                    value={decryptKey}
                    onChange={(e) => setDecryptKey(e)}
                />
                <CustomButton label="Preview Decryption" onClick={handlePreviewClick} />
                <CustomButton label="Decrypt All Data" onClick={handleDecryptClick} />
            </Stack>
            <Box bg="surface.background" p="2" rounded="md" maxWidth={"100%"}>
                {decodedMessages.map((message, index) => (
                    <Text key={index} whiteSpace="pre" fontSize="10px" overflow="hidden" textOverflow="ellipsis">
                        {message}
                    </Text>
                ))}
            </Box>
        </GenericContainer>
    );
};

export default MessageViewer;
