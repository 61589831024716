import React from "react";
import SideBar from "../components/common/SideBar";
import { Flex, Box } from "@chakra-ui/react";
import TopBar from "../components/common/TopBar";

const main = ({ children }) => {
    return (
        <Flex direction="row">
            <SideBar />
            <Flex direction={"column"} w="full">
                <TopBar />
                <Box as="main" p={4} w="full">
                    {children}
                </Box>
            </Flex>
        </Flex>
    );
};

export default main;
