import React, { useState, useEffect } from "react";
import { useClient } from '../../helpers/ClientContext';
import { Stack, Tooltip } from "@chakra-ui/react";
import GenericContainer from "../common/GenericContainer";
import CustomSelect from "../common/CustomSelect";
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@chakra-ui/react";


const Reset = () => {

    // Handle the MQTT client
    const client = useClient();
    useEffect(() => { }, [client]);

    const components = [
        "Battery",
        "EM Sensor",
        "GPS Sensor",
        "Infrared Camera",
        "Magnetometer",
        "Optical Camera",
        "Reaction Wheels",
        "Receiver",
        "Solar Panel"
    ]

    const [component, setPointingType] = useState(components[0]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [formData, setFormData] = useState({});

    const handleComponentChange = (event) => {
        setPointingType(event.target.value);
        setFormData({});
    };

    const handleButtonClick = () => {
        onOpen();
    };

    const confirmButtonClick = () => {
        client.publish_command(client.time, 'reset', { "component": component });
        onClose(); // Close the modal after confirming
    };

    return (
        <GenericContainer h="100%" title="Reset Component">
            <CustomSelect
                label="Component"
                value={component}
                onChange={handleComponentChange}
                options={components}
            />
            {component && (
                <>
                    <form>
                        <Stack direction="column" spacing="2" bg="surface.foreground" rounded="md" mt="2">

                            <Stack direction="row" spacing="4%" align="center">
                                <Tooltip
                                    label="Resets a particular component on the spacecraft to remove a corrupted feature."
                                    fontSize="md"
                                    placement="bottom-start"
                                >
                                    <Button type="button" width="100%"
                                        flexGrow="1" onClick={handleButtonClick}>
                                        Submit Reset
                                    </Button>
                                </Tooltip>
                                <Modal isOpen={isOpen} onClose={onClose}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>Confirm Reset</ModalHeader>
                                        <ModalBody>
                                            Are you sure you want to reset the {component}? Resetting components will remove any corrupted features on the component. 
                                            However, depending on the game, calling a reset might take some battery out of your spacecraft.
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button colorScheme="blue" mr={3} onClick={confirmButtonClick}>
                                                Confirm
                                            </Button>
                                            <Button onClick={onClose}>Cancel</Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>
                            </Stack>
                        </Stack>
                    </form>
                </>
            )}
        </GenericContainer>
    );
};

export default Reset;