import React from 'react';
import { Stack, Text, Select } from '@chakra-ui/react';

const CustomSelect = ({ label, value, onChange, options }) => {
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <Stack direction="column" spacing="0%">
            {label && (
                <Text fontSize={"12px"} paddingBottom={"0"}>
                    {capitalizeFirstLetter(label)}
                </Text>
            )}
            <Select value={value} onChange={onChange}>
                {options.map((type) => (
                    <option key={type} value={type}>
                        {type}
                    </option>
                ))}
            </Select>
        </Stack>
    );
};

export default CustomSelect;
